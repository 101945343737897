/* eslint-disable react/no-unescaped-entities */
import { graphql } from 'gatsby'
import React from 'react'

import Layout from '../../components/layout'
import SEO from '../../components/seo'
import '../../less/cms.less'

const PrivacyPolicy = () => {
  return (
    <Layout>
      <SEO title="Privacy Policy" />
      <div className="cms-container">
        <h1 className="cms-container__title">Privacy Policy</h1>
        <div className="cms-container__wrapper cms-container__wrapper__terms">
          <p>
            <strong>Last updated: December 4, 2017.</strong>
          </p>

          <p>
            The MealPal website and mobile applications (the “Site”) are operated by MealPal New Zealand Limited (NZBN:
            9429046876904z) ("MealPal"). The terms “MealPal,” “we,” “us,” and “our” refer to MealPal New Zealand Limited
            (NZBN: 9429046876904) and its related companies (as defined in the Companies Act 1993 ). The terms “you,”
            “your,” and “yours” refer to the user/customer utilising the Site.
          </p>

          <p>
            MealPal is committed to respecting and protecting your privacy. We are bound by the Information Privacy
            Principles (“IPPs”) in the Privacy Act 1993 ("Privacy Act"). To that end, we have prepared this privacy
            policy (“Privacy Policy”) to describe our information gathering and dissemination practices and our data
            protection practices for the Site. Please read this Privacy Policy carefully so that you understand our
            privacy practices. This Privacy Policy is governed by the Terms of Use governing the use of the Site found
            at{' '}
            <a
              href={`${process.env.GATSBY_LANDING_PAGE_URL}/nz/terms-and-conditions/`}
              target="_blank"
              rel="noreferrer"
            >
              {`${process.env.GATSBY_LANDING_PAGE_URL}/nz/terms-and-conditions/`}.
            </a>{' '}
            If you do not agree to this Privacy Policy, do not use the Site or give us any of your information.
          </p>

          <ol>
            <li>
              CHILDREN AND YOUNG PERSONS
              <p>
                The Site is not intended for or directed to persons under the age of 18, and we will not knowingly
                collect information from such persons. Any person who provides information to us through registration or
                in any other manner on the Site represents to MealPal that they are 18 years of age or older. If we
                learn that a person under 18 has submitted personal information to us, we will attempt to delete the
                information as soon as possible.
              </p>
            </li>
            <li>
              COLLECTION OF INFORMATION
              <p>
                “Personal Information” is defined in the Privacy Act and means information about an identifiable
                individual. We endeavour to limit our collection of personal information to information we believe is
                reasonably necessary to achieve our purposes as described in the “Information Use” section below.
              </p>
              <p>We collect Personal Information from you when you:</p>
              <ol type="a">
                <li>register for the Site, including providing details such as your name and email address;</li>
                <li>
                  participate in certain activities and obtain certain services or documents offered on the Site,
                  including your; phone number, billing address and credit card information and other Personal
                  Information that we collect in the course of a transaction between you and MealPal; and
                </li>
                <li>
                  contact us, including the Personal Information that we receive in any communications or materials that
                  you transmit to the Site by electronic mail or otherwise.
                </li>
              </ol>
              <p>
                When you visit the Site we also collect information from you, which may not be considered to be Personal
                Information, including:
              </p>
              <ol type="a">
                <li>your demographic information, including your age or gender identity; or</li>
                <li>
                  usage information, including (without limitation) the browser you are using, the URL that referred you
                  to the Site and the areas of the Site you visit.
                </li>
              </ol>
              <p>
                This information is de-identified and cannot by itself be used to identify or contact you. We may also
                supplement the information we collect with information from other sources to assist us in evaluating and
                improving our Site and offerings.
              </p>
              <p>
                We may collect Personal Information from individuals who are not customers of our business but whose
                Personal Information is given to us by those individuals via the Site or in the course of a transaction
                with MealPal.
              </p>
              <p>
                Where reasonably practicable, we attempt to collect information directly from individuals. When we
                collect information, we will take reasonable steps to ensure that the individual is aware of why we are
                collecting it, who we give it to and how we will use or disclose it or, alternatively, those matters
                will be obvious from the circumstances.
              </p>
              <p>
                If you choose not to provide us with Personal Information as requested, we may not be able to provide
                you with the services requested.{' '}
              </p>
            </li>
            <li>
              USE OF PERSONAL INFORMATION
              <p>
                We will use your Personal Information only for the primary purpose for which it was collected, or for a
                secondary purpose that is directly related to the primary purpose in connection with which the
                information was obtained. We may otherwise use your Personal Information if you have given us consent
                for the use or it is required or authorised by law.
              </p>
              <p>Generally, we use Personal Information for the following purposes:</p>
              <ol type="a">
                <li>to provide you with the Site, service information, and news;</li>
                <li>to support the Site and services offered on or through the Site;</li>
                <li>
                  to enable the sharing of MealPal member meal and restaurant choices with social media contacts, other
                  MealPal members and professional colleagues;
                </li>
                <li>to contact you for feedback about our services;</li>
                <li>to conduct research about MealPal’s customer base or services;</li>
                <li>to fulfil your reservation and order requests;</li>
                <li>to process your payments including credit checks and collection;</li>
                <li>to notify you of technical updates or changes in policy;</li>
                <li>to contact you for our own marketing and promotional purposes; or</li>
                <li>
                  to process contests, sweepstakes, or other promotions and fulfil any related awards or discounts.
                </li>
              </ol>
              <p>
                We may use de-identified information to analyse and develop our marketing strategy and to alter, modify,
                introduce or further improve any aspect, feature, or policy of the Site and our services.
              </p>
            </li>
            <li>
              DISCLOSURE OF PERSONAL INFORMATION
              <p>
                We will generally disclose your Personal Information only for the primary purpose for which it was
                collected, or for a related secondary purpose that is directly related to the primary purpose in
                connection with which the information was obtained. We may otherwise use and disclose your personal
                information if you have given us consent for the disclosure or it is required or authorised by law.
              </p>
              <p>
                Generally, we may disclose your Personal Information to other companies or people under any of the
                following circumstances:
              </p>
              <ol type="a">
                <li>
                  to any applicable merchant, restaurant, or meal service provider for each meal that you sign up for on
                  the Site, so it can contact you regarding the meal you reserved and send you other communication that
                  may be of interest as special offers (you may opt-out of marketing communications directly with the
                  applicable merchant).
                </li>
                <li>
                  if sharing the information is reasonably necessary to provide or otherwise make available the Site and
                  any feature of the Site or a service that you have requested.
                </li>
                <li>
                  to keep you up to date on the latest product announcements, software updates, special offers, or other
                  information we think you would like to hear about either from us or from our business partners (unless
                  you have opted out of these types of communications). (Note that you will not be able to opt-out of
                  service announcements that contain important information relevant to your use of the Site and are not
                  promotional in nature).
                </li>
                <li>
                  to share your meal and restaurant choices with your social media contacts, other MealPal members, and
                  professional colleagues, including via third party social media sites.
                </li>
                <li>
                  if we believe in good faith that we are required to do so by law, in connection with litigation, to
                  prevent a crime, or to protect personal property, the public, or the Site.
                </li>
                <li>
                  in connection with a sale or merger with another entity, consolidation, restructuring, sale of company
                  assets, financing or other corporate change, including during the course of any due diligence process
                  or if MealPal should ever file for bankruptcy or related proceeding.
                </li>
                <li>
                  to your employer (or similar entity), if you participate in any of enterprise solutions or corporate
                  programs.
                </li>
                <li>when we otherwise have your consent to share the information.</li>
              </ol>
              <p>
                If those purposes for which we have collected the information involve providing Personal Information
                about an individual to any third party, we will take appropriate and reasonable steps to ensure any
                Personal Information is protected.
              </p>
              <p>
                MealPal will not sell or rent your Personal Information to third parties except as described in this
                Privacy Policy. However, you acknowledge and agree that MealPal may share de-identified Personal
                Information with third parties, and MealPal will undertake appropriate and reasonable efforts to see
                that any third party to whom MealPal discloses such de-identified Personal Information is under an
                obligation to use that de-identified Personal Information solely for the purposes for which that
                information was disclosed.
              </p>
            </li>
            <li>
              DIRECT MARKETING
              <p>We will only use personal information for marketing if you have given express or inferred consent.</p>
              <p>
                We will give you the opportunity to “opt out” of receiving direct marketing or market research
                information, as well as the opportunity to “opt out” of having your ordering information shared with
                your social media contacts, other MealPal members, and other professional colleagues, through the “My
                Account” section of the Site. This means we assume you have given us your consent to collect and use
                your information, such as your email address, in accordance with this Privacy Policy, for direct
                marketing purposes, unless you take affirmative action to indicate that you do not consent, for instance
                by clicking or checking the appropriate option or box at the point of collection. Our electronic
                marketing activities will comply with the requirements of the Unsolicited Electronic Messages Act 2007
              </p>
            </li>
            <li>
              SECURITY
              <p>
                We are committed to protecting the Personal Information we collect and hold against loss and
                unauthorised access, use, modification or disclosure, and against other misuse. MealPal takes reasonable
                precautions to safeguard the confidentiality of Personal Information. We protect the security of your
                Personal Information during transmission to us by using Secure Sockets Layer ("SSL") software, which
                encrypts the information you input. When no longer required, Personal Information is destroyed in a
                secure manner or deleted.
              </p>
            </li>
            <li>
              THIRD PARTY SITES
              <p>
                The Site may contain links to, or allow sharing with, third-party websites and applications. MealPal is
                not responsible for the privacy policies of such other sites. We encourage our users to be aware when
                leaving the Site to read the privacy statements of each site that collects Personal Information.
              </p>
            </li>
            <li>
              COOKIES AND ADVERTISING
              <ol type="a">
                <li>
                  We use cookies and other similar technologies (such as actions tags, also known as beacons, or pixels
                  tags) for a number of purposes including to remember preferences, track conversions, conduct marketing
                  and promotional efforts, analyse site traffic and trends, and generally understand the online
                  behaviours and interests of people who interact with our Site.
                </li>
                <li>
                  We use third-party advertising companies to serve advertisements on our behalf or to provide
                  advertising-related services. These companies may use a cookie or an action tag, to tailor the
                  advertisements you see on the Site and other sites, to track your response to their advertisement, to
                  determine whether advertising has been served and to measure the effectiveness of their advertising or
                  to award incentives or points to their members who respond to their advertisements. The cookies,
                  action tags or similar files do not contain any Personal Information.
                </li>
                <li>
                  We may also use third parties to provide measurement and analytic services. You can find more
                  information on web analytics by visiting{' '}
                  <a href="https://www.privacy.org.nz/" target="_blank" rel="noreferrer">
                    https://www.privacy.org.nz/
                  </a>
                </li>
              </ol>
            </li>
            <li>
              SOCIAL MEDIA FEATURES AND WIDGETS
              <p>
                Our Site may integrate with social sharing features or other related tools which enable you or us to
                share actions you take on our Site with other apps, sites or media, and vice versa. These features may
                enable the sharing of information with your friends, professional colleagues, other MealPal members, or
                the public, depending on the settings you establish with the social sharing service. These features may
                also collect your IP address and which page you are visiting on our Site, and may set a cookie to enable
                the feature to function properly. Your interaction with these features are governed by the privacy
                policy of the relevant Site. Please refer to the privacy policies of those social sharing services for
                more information.
              </p>
            </li>
            <li>
              PUBLIC POSTS
              <p>
                The Site may allow you to post or share content to a public area of the Site such as a public profile,
                group, community or other public area. You should be aware that any content or information you choose to
                disclose in these areas can be read, collected and used by other users, the general public and other
                sites (including search engines). We are not responsible for, and this Privacy Policy does not apply to,
                information you may choose to post in these areas.
              </p>
            </li>
            <li>
              YOUR CONSENT TO THIS PRIVACY POLICY
              <p>
                By using this Site, you agree to this Privacy Policy. We may occasionally update this Privacy Policy.
                The date of the most recent update of this document will always be displayed at the beginning of this
                page. If we change our Privacy Policy, we will post those changes on{' '}
                {`${process.env.GATSBY_LANDING_PAGE_URL}/nz/`} If we decide to use Personal Information in a manner
                different from that stated at the time it was collected, we will notify users via email (if an email
                address has been provided) or display a notice on {`${process.env.GATSBY_LANDING_PAGE_URL}/nz/`}. We
                encourage you to periodically review this Privacy Policy to stay informed about how we are protecting
                the Personal Information we collect. Your continued use of this Site constitutes your agreement to this
                Privacy Policy and any updates.
              </p>
            </li>
            <li>
              ACCESS OR CORRECT PERSONAL INFORMATION
              <p>
                MealPal seeks to ensure all Personal Information that we collect, use and disclose is accurate,
                up-to-date and complete. Under the Privacy Act, you have certain rights to access and correct the
                Personal Information we collect and hold about you.
              </p>
              <p>
                We will allow an individual access to, or correction of, the Personal Information we hold about them at
                any time. When requesting such access, please identify the precise type/s of information requested.
              </p>
              <p>
                You may make a request to access or correct the Personal Information we collect and hold about you by
                contacting us at the contact details provided below. MealPal will meet or advise of the outcome of such
                a request as soon as reasonably possible, but in any case within 20 working days after it is received.
              </p>
              <p>
                If we are unwilling to correct any personal information held about you, we will on your request attach a
                statement to the information noting the correction sought.{' '}
              </p>
            </li>
            <li>
              MAKE A COMPLAINT
              <p>
                For any privacy issues or concerns, including a complaint about a breach of the IPPs, which includes how
                we handle your personal information, you may contact us at the contact details provided below.
              </p>
              <p>
                We may request that you make your complaint in writing. We will endeavour to respond to your complaint
                within a reasonable time after it is received. If you are not satisfied by our response, you may make
                further enquiries to the Office of the New Zealand Privacy Commissioner at{' '}
                <a href="https://www.privacy.org.nz/about-us/contact/" target="_blank" rel="noreferrer">
                  https://www.privacy.org.nz/about-us/contact/.
                </a>
              </p>
            </li>
            <li>
              CONTACTING MEALPAL
              <p>If you have any questions, comments, or concerns about this Privacy Policy, please contact us at:</p>
              <p>MealPal New Zealand Limited (NZBN: 9429046876904)</p>
              <p>Level 20</p>
              <p>88 Shortland Street</p>
              <p>Auckland Central,</p>
              <p>Auckland, 1010, NZ</p>
              <p>
                Email: <a href="mailto:hi@mealpal.com">hi@mealpal.com</a>
              </p>
            </li>
          </ol>
        </div>
      </div>
    </Layout>
  )
}

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`

export default PrivacyPolicy
